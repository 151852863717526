import { generateXHRError } from '@helpers';

export default {
  name: 'Converter',
  data() {
    return {
      convertMode: 'xml',
      convertibleCode: '',
      convertResult: '',
    };
  },
  computed: {
    isInvalidForm() {
      return !this.convertibleCode;
    },
  },
  methods: {
    changeConvertMode() {
      this.convertMode = this.convertMode === 'xml' ? 'javascript' : 'xml';
    },
    async convertData() {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        const { data } = await this.$http.post('orders/convert', this.convertibleCode, {
          headers: {
            'Content-Type': `application/${this.convertMode === 'javascript' ? 'json' : 'xml'}`,
          },
        });
        this.convertResult = JSON.stringify(data || '', null, '\t');
      } catch (e) {
        this.$VBlackerTheme.notification.error(generateXHRError(e));
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
    resetFormData() {
      this.convertibleCode = '';
      this.convertResult = '';
    },
  },
};
